// extracted by mini-css-extract-plugin
export var portfolio = "projects-module--portfolio--I0qeS";
export var projects = "projects-module--projects--RoR2i";
export var projectCard = "projects-module--projectCard--1Gzh9";
export var container = "projects-module--container--3DCbt";
export var button = "projects-module--button--3J_EJ";
export var meta = "projects-module--meta--1mPFl";
export var excerpt = "projects-module--excerpt--VMBWR";
export var tagsWrapper = "projects-module--tagsWrapper--2KIk3";
export var tag = "projects-module--tag--6ccjp";
export var sourceCode = "projects-module--sourceCode--23iSg";